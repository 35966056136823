import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAtom } from 'jotai';
import { ParsedUrlQuery } from 'querystring';
import { useState } from 'react';
import { userState } from '../../state/userState';
import { SavedSearchAPIResult } from '../../types/api/searchTypes';
import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { SearchCMSResult } from '../../types/cms/searchTypes';
import { track } from '../../utils/analytics';
import { isEmpty, removeEmptyValues } from '../../utils/common';
import { Button } from 'legacy-ui';
import { Heading } from 'legacy-ui';
import { LocationSearchParams } from '../LocationSuggestionBox/types';
import { Modal, ModalButtons, ModalHeader, ModalText } from 'legacy-ui';
import { Text } from 'legacy-ui';
import { getSavedSearchDetailsText, queryIsInSavedSearches } from './functions';
import { SavedSearchContainer, SearchDetailsBox } from './styles';

interface SavedSearchBoxProps {
  query: ParsedUrlQuery;
  saveSearch: () => Promise<void>;
  savedSearches: SavedSearchAPIResult[];
  justSaved: boolean;
  fromCMS: SearchCMSResult;
  generalTexts: GeneralCMSResult;
}

const SavedSearchBox = ({ query, saveSearch, savedSearches, justSaved, fromCMS, generalTexts }: SavedSearchBoxProps) => {
  const [{ user }, setUser] = useAtom(userState);
  const [showModal, setShowModal] = useState(false);

  async function openModal() {
    setShowModal(true);

    await track('User Saved Search Initiate');
  }

  if (justSaved) {
    return (
      <SavedSearchContainer boxType="searchSaved">
        <div>
          <Heading>{fromCMS.SavedSearchSavedHeadline}</Heading>
          <Text>{fromCMS.SavedSearchSavedDescription}</Text>
        </div>
      </SavedSearchContainer>
    );
  }
  const { page, ...queryWithoutPage } = query;

  if (isEmpty(removeEmptyValues(queryWithoutPage)) || !query.viewportCoordinates) {
    return null;
  }

  if (queryIsInSavedSearches(query, savedSearches)) {
    return (
      <SavedSearchContainer boxType="searchExists">
        <div>
          <Heading>{fromCMS.SavedSearchExistsHeadline}</Heading>
          <Text>{fromCMS.SavedSearchExistsDescription}</Text>
        </div>
      </SavedSearchContainer>
    );
  }
  const { viewportCoordinates } = query;
  const { location }: LocationSearchParams = JSON.parse(viewportCoordinates as string);
  const savedSearchDetails = getSavedSearchDetailsText(query, generalTexts, 'integer');
  return (
    <>
      <SavedSearchContainer boxType="saveSearch">
        <div>
          <Heading>{fromCMS.SavedSearchSaveHeadline}</Heading>
          <Text>{fromCMS.SavedSearchSaveDescription}</Text>
        </div>
        <Button color="white" small onClick={() => (user ? openModal() : setUser((state) => ({ ...state, showLoginPopup: true })))}>
          <FontAwesomeIcon icon={faBell} /> {fromCMS.SavedSearchSaveCTA}
        </Button>
      </SavedSearchContainer>
      <Modal isOpen={showModal} closeModal={() => setShowModal(false)}>
        <ModalHeader>
          <Heading size="s" tag="h3">
            {fromCMS.SavedSearchModalHeadline}
          </Heading>
          <Text>{fromCMS.SavedSearchModalDescription}</Text>
        </ModalHeader>
        <ModalText>
          <SearchDetailsBox>
            <Text weight="m">{location.label}</Text>
            {savedSearchDetails && <Text>{savedSearchDetails}</Text>}
          </SearchDetailsBox>
        </ModalText>
        <ModalButtons>
          <div>
            <Button
              fullWidth
              color="primary"
              onClick={async () => {
                await saveSearch();
                setShowModal(false);
              }}
            >
              {generalTexts.Misc.Continue}
            </Button>
          </div>
          <div>
            <Button fullWidth onClick={() => setShowModal(false)}>
              {generalTexts.Misc.Cancel}
            </Button>
          </div>
        </ModalButtons>
      </Modal>
    </>
  );
};

export default SavedSearchBox;
