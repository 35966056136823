import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Button } from 'legacy-ui';
import AvailabilityFilter from '../../components/Filters/AvailabilityFilter';
import LocationFilter from '../../components/Filters/LocationFilter';
import OtherFilters from '../../components/Filters/OtherFilters';
import PriceFilter from '../../components/Filters/PriceFilter';
import PropertyTypeFilter from '../../components/Filters/PropertyTypeFilter';
import RoomsFilter from '../../components/Filters/RoomsFilter';
import SizeFilter from '../../components/Filters/SizeFilter';
import { FiltersApplyButton, FiltersHeader, FiltersWrapper } from '../../components/Filters/styles';
import { EMPTY_FILTER, FilterState } from '../../components/Filters/types';
import { Heading } from 'legacy-ui';
import { Text } from 'legacy-ui';
import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { LanguageKey } from '../../types/common';
import { isEmpty, removeEmptyValues, shallowCompare } from '../../utils/common';
import { getRoute } from '../../utils/languages';

interface FiltersProps {
  generalTexts: GeneralCMSResult;
  autoUpdate?: boolean;
  onManualUpdate?: (state: FilterState) => void;
  waitingLists?: boolean;
}

const Filters = ({ generalTexts, autoUpdate, onManualUpdate, waitingLists }: FiltersProps) => {
  const router = useRouter();
  const { query, isReady } = router;

  const [filterState, setFilterState] = useState<FilterState>(EMPTY_FILTER);

  useEffect(() => {
    if (isReady && !isEmpty(query)) {
      setFilterState({
        viewportCoordinates: (query.viewportCoordinates as string) || '',
        priceMax: (query.priceMax as string) || '',
        sizeMin: (query.sizeMin as string) || '',
        roomsMin: (query.roomsMin as string) || '',
        availableFrom: (query.availableFrom as string) || '',
        petsAllowed: (query.petsAllowed as string) || '',
        handledBy: (query.handledBy as string) || '',
        propertyTypes: (query.propertyTypes as string) || '[]',
        tags: (query.tags as string) || '[]',
        upcomingProject: (query.upcomingProject as string) || '',
        radius: (query.radius as string) || '',
        page: (query.page as string) || '',
        zipCodes: (query.zipCodes as string) || '',
      });
    }
  }, [query, isReady]);

  function updateUrl(state: FilterState) {
    const { isReady, locale, pathname, query } = router;
    if (!isReady || shallowCompare(removeEmptyValues(query), removeEmptyValues(state))) {
      return;
    }
    const queryObject = removeEmptyValues({ ...state, page: '1' });
    router.push({ pathname: getRoute(pathname, locale as LanguageKey), query: queryObject }, undefined, {
      shallow: false,
    });
  }

  return (
    <FiltersWrapper>
      <FiltersHeader>
        <Heading tag="h2">{generalTexts.Misc.Filters}</Heading>
        <Text
          size="s"
          onClick={() => {
            setFilterState(EMPTY_FILTER);
            updateUrl(EMPTY_FILTER);
          }}
        >
          {generalTexts.Misc.Clear} {generalTexts.Misc.Filters.toLowerCase()}
        </Text>
      </FiltersHeader>
      <LocationFilter
        generalTexts={generalTexts}
        internalState={filterState}
        onChange={(partialState) => {
          const newState = { ...filterState, ...partialState };
          setFilterState(newState);
          autoUpdate && updateUrl(newState);
        }}
      />
      <PriceFilter
        generalTexts={generalTexts}
        internalState={filterState}
        onChange={(value) => {
          const newState = { ...filterState, priceMax: value };
          setFilterState(newState);
          if (!value.length || value.length > 3) {
            autoUpdate && updateUrl(newState);
          }
        }}
      />
      <PropertyTypeFilter
        generalTexts={generalTexts}
        internalState={filterState}
        onChange={(partialState) => {
          const newState = { ...filterState, ...partialState };
          setFilterState(newState);
          autoUpdate && updateUrl(newState);
        }}
      />
      <SizeFilter
        generalTexts={generalTexts}
        internalState={filterState}
        onChange={(value) => {
          const newState = { ...filterState, sizeMin: value };
          setFilterState(newState);
          if (value.length !== 1) {
            autoUpdate && updateUrl(newState);
          }
        }}
      />
      <RoomsFilter
        generalTexts={generalTexts}
        internalState={filterState}
        onChange={(value) => {
          const newState = { ...filterState, roomsMin: value };
          setFilterState(newState);
          autoUpdate && updateUrl(newState);
        }}
      />
      {!waitingLists ? (
        <AvailabilityFilter
          generalTexts={generalTexts}
          internalState={filterState}
          onChange={(value) => {
            const newState = { ...filterState, availableFrom: value };
            setFilterState(newState);
            autoUpdate && updateUrl(newState);
          }}
        />
      ) : (
        <></>
      )}
      <OtherFilters
        generalTexts={generalTexts}
        internalState={filterState}
        waitingLists={waitingLists}
        onChange={(partialState) => {
          const newState = { ...filterState, ...partialState };
          setFilterState(newState);
          autoUpdate && updateUrl(newState);
        }}
      />
      {!autoUpdate && (
        <FiltersApplyButton>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              onManualUpdate && onManualUpdate(filterState);
              updateUrl(filterState);
            }}
          >
            {generalTexts.Misc.ApplyFilters}
          </Button>
        </FiltersApplyButton>
      )}
    </FiltersWrapper>
  );
};

export default Filters;
