import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import Pagination from 'rc-pagination';
import styled from 'styled-components';

const StyledPageSelect = styled(Pagination)`
  display: flex;
  justify-content: center;
  li {
    color: ${(props) => props.theme.color.propertySearchPaginationTextColor};
    line-height: 36px;
    font-weight: 500;
    margin: 0 8px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      margin: 0 16px;
    }
    > div {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.color.propertySearchPaginationTextColorHover};
        background: ${(props) => props.theme.color.propertySearchPaginationBackgroundColorHover};
      }
      &:active {
        color: ${(props) => props.theme.color.propertySearchPaginationTextColorActive};
        background: ${(props) => props.theme.color.propertySearchPaginationBackgroundColorActive};
      }
    }
    &.rc-pagination-disabled,
    &.rc-pagination-item-active {
      color: ${(props) => props.theme.color.propertySearchPaginationTextDisabledColor};
      > div {
        cursor: default;
        &:hover,
        &:active {
          background: none;
        }
      }
    }
  }
`;

interface PageSelectProps {
  totalResults: number;
  pageSize: number;
  page: number;
  setPage: (page: number) => void;
  SEO?: SEOProps;
}

interface SEOProps {
  canonicalPath: string;
}

const PageSelectContent = (
  currentPage: number,
  type: string,
  SEO: SEOProps | undefined,
  handleOnPageClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
) => {
  if (type === 'jump-prev' || type === 'jump-next') {
    return '...';
  }
  if (type === 'prev') {
    return (
      <div>
        <FontAwesomeIcon icon={faAngleLeft} />
      </div>
    );
  }
  if (type === 'next') {
    return (
      <div>
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
    );
  }

  if (!SEO) {
    return <div>{currentPage}</div>;
  }

  return (
    <div>
      <a href={currentPage === 1 ? SEO.canonicalPath : `${SEO.canonicalPath}?page=${currentPage}`} onClick={(event) => handleOnPageClick(event)}>
        {currentPage}
      </a>
    </div>
  );
};

const PageSelect = ({ totalResults, pageSize, page, setPage, SEO }: PageSelectProps) => {
  const { locale } = useRouter();

  const adjustedSEO = SEO && { ...SEO, canonicalPath: `/${locale}${SEO.canonicalPath}` };

  const handleOnPageClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setPage(Number(event.currentTarget.textContent));
  };
  return (
    <StyledPageSelect
      showLessItems
      current={page}
      total={totalResults}
      pageSize={pageSize}
      onChange={setPage}
      locale={{ prev_page: '<', next_page: '>' }}
      itemRender={(currentPage, type) => {
        return PageSelectContent(currentPage, type, adjustedSEO, handleOnPageClick);
      }}
    />
  );
};

export default PageSelect;
