import styled from 'styled-components';

export const OverviewWrapper = styled.div`
  margin-bottom: 64px;
`;

export const OverviewHeader = styled.div<{ propstepManaged: boolean }>`
  margin-bottom: 16px;
  border-radius: 8px;
  color: ${(props) =>
    props.propstepManaged
      ? props.theme.color.propertyGroupOverviewHeaderTextColorPropstepManaged
      : props.theme.color.propertyGroupOverviewHeaderTextColor};
  background: ${(props) =>
    props.propstepManaged
      ? props.theme.color.propertyGroupOverviewHeaderBackgroundColorPropstepManaged
      : props.theme.color.propertyGroupOverviewHeaderBackgroundColor};

  > div > div {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    display: flex;
  }
`;

export const OverviewHeaderImage = styled.div`
  flex: 0 0 280px;
  width: 280px;
  min-height: 164px;
  position: relative;
  &:hover {
    opacity: 0.9;
  }
`;

export const OverviewHeaderImageInner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  overflow: hidden;
  top: 0;
  left: 0;
`;

export const OverviewHeaderImageInnerLabel = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 2;
  p {
    background: #ffed48;
    color: ${(props) => props.theme.color.secondaryTextColor};
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: 600;
  }
`;

export const OverviewHeaderDetails = styled.div`
  flex: 1 1 auto;
  padding: 24px 24px 16px 16px;
  position: relative;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding: 24px 24px 16px 24px;
  }
  h2 {
    word-break: break-word;
    hyphens: auto;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const OverviewHeaderDetailsCompany = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  > div {
    &:nth-of-type(1) {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      img {
        border-radius: 100%;
      }
    }
    &:nth-of-type(2) {
      flex: 1 1 auto;
    }
  }
  &:hover {
    > div {
      &:nth-of-type(2) {
        text-decoration: underline;
      }
    }
  }
`;
