import { useRouter } from 'next/router';
import Link from 'next/link';
import { Button } from 'legacy-ui';
import { getWaitingListLink } from 'utils/links';
import { useTranslation } from 'next-i18next';
import { PropertyGroupAPIResult } from 'types/api/propertyGroupTypes';
import { PropertyAPIResult } from 'types/api/propertyTypes';
import { PROPERTY_AVAILABLE } from 'utils/constants';
import { LanguageKey } from 'types/common';
import { useEffect, useState } from 'react';
import LeaveWaitingListModal from './LeaveWaitingListModal';
import { faBellSlash } from '@fortawesome/pro-solid-svg-icons';
import { useWaitingListApi } from 'hooks/waiting-list/useWaitingListApi';
import { WaitingListEntryResponse } from 'schemas/waiting-lists/WaitingListEntryResponse';
import { useAtom } from 'jotai';
import { userState } from 'state/userState';

type WaitingListModalStatus = 'none' | 'leave';

interface WaitingListButtonProps {
  propertyGroup: PropertyGroupAPIResult;
  properties?: PropertyAPIResult[];
  availablePropertiesCallback?: () => void;
  joinButtonColor?: string;
  leaveButtonColor?: string;
}

const WaitingListButton = ({ propertyGroup, properties, availablePropertiesCallback, joinButtonColor, leaveButtonColor }: WaitingListButtonProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation('waitingList');
  const [{ user }] = useAtom(userState);
  const { getWaitingListEntryForPropertyGroup, leaveWaitingList } = useWaitingListApi();
  const [waitingListModalStatus, setWaitingListModalStatus] = useState<WaitingListModalStatus>('none');
  const [waitingListEntry, setWaitingListEntry] = useState<WaitingListEntryResponse | null>(null);

  useEffect(() => {
    if (user && waitingListEntry === null) {
      getWaitingListEntryForPropertyGroup(propertyGroup.id).then((entry) => {
        if (entry.data) {
          setWaitingListEntry(entry.data);
        }
      });
    }
  }, [propertyGroup.id, user]);

  const leaveWaitingListClick = () => {
    if (waitingListEntry) {
      leaveWaitingList(waitingListEntry.id).then(() => {
        setWaitingListModalStatus('none');
        setWaitingListEntry(null);
      });
    }
  };

  const availableProperties = properties?.filter((property) => property.transactionStatus === PROPERTY_AVAILABLE) ?? [];
  const showAvailablePropertiesButton = availableProperties.length > 0;
  return (
    <>
      {waitingListEntry ? (
        <>
          <Button
            fullWidth={!showAvailablePropertiesButton}
            color={leaveButtonColor}
            leftIcon={faBellSlash}
            onClick={() => setWaitingListModalStatus('leave')}
          >
            {t('Leave waiting list')}
          </Button>
          <LeaveWaitingListModal
            isOpen={waitingListModalStatus === 'leave'}
            onClose={() => setWaitingListModalStatus('none')}
            leaveWaitingList={leaveWaitingListClick}
          />
        </>
      ) : (
        <Link href={getWaitingListLink(propertyGroup, locale as LanguageKey, '/apply')}>
          <Button fullWidth={!showAvailablePropertiesButton} color={joinButtonColor}>
            {t('Join waiting list')}
          </Button>
        </Link>
      )}

      {showAvailablePropertiesButton && (
        <Button color="primary" onClick={availablePropertiesCallback}>
          {t('availableProperties', { count: availableProperties.length })}
        </Button>
      )}
    </>
  );
};

export default WaitingListButton;
